import {Alert, Button, message, Modal, Table} from 'antd';
import React from 'react';
import Team from '../../Pages/Team/Team';

type Props = {
    open: boolean;
    onCancel: () => void;
    message?: string;
};

export interface IWindow extends Window {
    my_uuid?: string;
}

const w: IWindow = window;

export default function PayForUser({open, onCancel, message}: Props) {
    return (
        <Modal
            open={open}
            onCancel={() => {
                onCancel();
            }}
            footer={false}
            title='Árgjald - 5.990 kr.'
        >
            {message && <Alert message={message} type='error'></Alert>}

            <div>
                <h4>Innifalið í árgjaldi</h4>

                <div>
                    <Table
                        size='small'
                        pagination={false}
                        dataSource={[
                            {
                                title: 'SMS og Messenger tilkynningar',
                            },
                            {
                                title: 'Hægt að skipta í lið',
                            },
                            {
                                title: 'Hægt að fela liðið fyrir almenningi',
                            },
                        ]}
                        showHeader={false}
                        columns={[
                            {
                                title: '',
                                dataIndex: 'title',
                                key: 'title',
                            },
                            {
                                title: 'Netfang',
                                dataIndex: 'email',
                                key: 'email',
                            },
                        ]}
                    ></Table>

                    <Button
                        href={
                            'https://repeat.is/repeat_checkout/da79b628-ff2b-4c6d-b078-dbe1066d132f/?products.0.uuid=3c68fdcd-9323-4a7e-a825-b19a105ab936&products.0.quantity=1&currency=ISK&language=is&interval_type=YEAR&interval_count=1&name=&email=&external_ref=' +
                            w.my_uuid
                        }
                        type='primary'
                        block
                        size='large'
                    >
                        Greiða árgjald
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
